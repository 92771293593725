import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { updatePartOrder } from 'app/containers/SiteWrapper/slice';
import { Carrier } from 'app/pages/ToursPage/types';
import ProductionDate from 'app/containers/OrderDetails/HeaderOrderDetails/ProductionDate';
import EmployeOrder from 'app/containers/OrderDetails/HeaderOrderDetails/EmployeOrder';
import DragOrderDetails from 'app/containers/OrderDetails/HeaderOrderDetails/DragOrderDetails';
import { RiCalendarCheckLine } from 'react-icons/ri';
import { CarrierSelector } from 'app/containers/OrderDetails/HeaderOrderDetails/CarrierSelector';
import { FiFlag, FiX } from 'react-icons/fi';
import { selectedDirectTour } from 'app/containers/ToursList/selectors';
import { addDelivery, addNewTour } from 'app/containers/ToursList/slice';
import { findTour, findTourShop } from 'app/pages/ToursPage/tourHelpers';
import PrintOrder from 'app/containers/OrderDetails/HeaderOrderDetails/PrintOrder';
import { BsInfoSquareFill } from 'react-icons/bs';
import { Tour } from 'app/models/Tour';
import { KewnetStatus } from 'app/constants';
import { PartOrder } from 'app/models/PartOrder';
import { FaHammer } from 'react-icons/fa';
import OrdersNavigator from 'app/containers/OrderDetails/HeaderOrderDetails/OrdersNavigator';
import PartOrderBanner from 'app/containers/OrderDetails/PartOrderBanner';
import PrintDirectPartOrder from 'app/containers/OrderDetails/PartOrderSelection/PrintDirectPartOrder';
import DestinationSelector from './DestinationSelector';
import InfoLocation from 'app/pages/ToursPage/InfoLocation';
import {
  useAddDeliveryMutation,
  useUpdateDeliveryMutation,
} from 'services/kewloxApi';
import { selectCurrentDelivery } from 'store/slices/activeContentSlice';
import { Delivery } from 'app/models/Delivery';
import { parseISO } from 'date-fns';
import { selectAllTours } from 'store/slices/toursSlice';

interface Props {
  partOrder: PartOrder;
  displayOnly: boolean;
  close: any;
  navigateInOrders: boolean;
}

const HeaderOrderDetails = ({
  partOrder,
  displayOnly,
  close,
  navigateInOrders,
}: Props) => {
  const dispatch = useDispatch();
  const directTour = useSelector(selectedDirectTour);
  const tours = useSelector(selectAllTours);
  const productionDateOrder = partOrder.productionDate;

  const delivery: Delivery | undefined = useSelector(selectCurrentDelivery);

  const [
    updateDeliveryMutation,
    { isLoading: updateDeliveryLoading },
  ] = useUpdateDeliveryMutation();
  useAddDeliveryMutation();

  const onClickCarrier = (carrier: Carrier) => {
    if (productionDateOrder) {
      // We have a production date and a carrier, so we could assign the delivery to a tour
      const tour: Tour | undefined = findTour(
        parseISO(productionDateOrder),
        carrier,
        partOrder.destination,
        tours,
      );
      if (tour) {
        if (delivery) {
          updateDeliveryMutation({
            id: delivery.id,
            partOrder: delivery.partOrder,
            tour: tour.id,
          })
            .unwrap()
            .then(() => {
              console.log('Données mise à jours');
            })
            .catch(error => {
              console.log('Error', error);
            });
        } else {
          dispatch(addDelivery(tour, 0, partOrder));
        }
      } else {
        if (delivery) {
          dispatch(
            addNewTour(productionDateOrder, carrier.id, partOrder, delivery),
          );
        } else {
          dispatch(addNewTour(productionDateOrder, carrier.id, partOrder));
        }
      }
    } else {
      dispatch(updatePartOrder(partOrder, carrier, undefined));
    }
  };

  const onValidateDirectOrder = () => {
    if (!delivery && directTour) {
      dispatch(addDelivery(directTour, 0, partOrder));
    }
  };

  const onAutoAllocationOrder = () => {
    if (tours) {
      const tour = findTourShop(partOrder.destination, tours);
      if (
        tour &&
        !delivery &&
        partOrder.destination &&
        partOrder.destination.shop
      ) {
        dispatch(addDelivery(tour, 0, partOrder));
      }
    }
  };

  function renderPrintButton() {
    if (
      delivery !== undefined &&
      partOrder.packages &&
      partOrder.packages.length > 0
    ) {
      if (partOrder.direct) {
        return <PrintDirectPartOrder partOrder={partOrder} />;
      } else {
        return <PrintOrder order={partOrder} />;
      }
    }
    return null;
  }

  let partOrderWeight = 0;
  partOrder?.packages?.forEach(pkg => {
    if (pkg.weight !== undefined) partOrderWeight += pkg.weight;
  });

  return (
    <div className="flex flex-col items-center">
      {partOrder.orderInfo.statusId === KewnetStatus.closed && (
        <div className="bg-green-dark w-full flex justify-center text-white py-2 text-lg">
          Cette commande est clôturée
        </div>
      )}
      <PartOrderBanner partOrder={partOrder} />

      <div className="flex w-11/12">
        <div className="flex w-12 flex-grow-0 flex-shrink-0 justify-center items-center">
          {delivery !== undefined ? (
            <RiCalendarCheckLine className="h-10 w-10 text-green-dark" />
          ) : (
            <>{!displayOnly && <DragOrderDetails orderId={partOrder.id} />}</>
          )}
        </div>
        <div className="flex-grow">
          <div className="flex items-center justify-around">
            <OrdersNavigator partOrder={partOrder} />
            <div className="text-l text-gray-800 font-hairline mx-5 w-48">{`${partOrder.orderInfo.firstname
              .charAt(0)
              .toUpperCase()}. ${partOrder.orderInfo.lastname.toUpperCase()}`}</div>
          </div>
          <div className="flex items-center justify-around">
            <div className="flex items-center justify-center">
              {partOrder.assembly && (
                <div className="text-xl mr-4 font-bold text-red">
                  <FaHammer className="bottom-hammer" />
                </div>
              )}
              <DestinationSelector partOrder={partOrder} />
              {partOrder.destination.home && (
                <div className="ml-2 font-semibold">
                  <InfoLocation
                    province={partOrder.orderInfo.province}
                    isoCode={partOrder.orderInfo.isoCode}
                  />
                </div>
              )}
            </div>
            <EmployeOrder order={partOrder.orderInfo} />
          </div>
          {!partOrder.direct && (
            <div className="flex mt-2 justify-around">
              <div className="flex items-center ml-4 text-gray-800">
                <CarrierSelector
                  order={partOrder}
                  onClick={onClickCarrier}
                  selected={partOrder.carrier}
                />
                {partOrder.carrier?.name.toLocaleUpperCase() === 'BRENGER' &&
                  partOrderWeight > 190000 && (
                    <>
                      <span className="bg-red p-2 ml-2 text-white">DUO</span>
                    </>
                  )}
              </div>
              <ProductionDate partOrder={partOrder} delivery={delivery} />
              {!displayOnly &&
                !delivery &&
                !partOrder.direct &&
                partOrder.destination.shop && (
                  <button
                    onClick={onAutoAllocationOrder}
                    className="py-1 px-3 border flex justify-center items-center w-32 bg-green text-white"
                  >
                    <div>Attrib. auto</div>
                  </button>
                )}
            </div>
          )}
          {!displayOnly && !delivery && partOrder.direct && (
            <div className="flex justify-center py-1">
              <button
                onClick={onValidateDirectOrder}
                className="py-1 px-3 border flex justify-center items-center w-56 bg-green text-white"
              >
                <div className="flex items-center">
                  Valider la commande <FiFlag className="h-4 w-4 mx-2" />
                </div>
              </button>
            </div>
          )}
        </div>
        <div className="flex flex-col justify-center items-center">
          <div className="flex flex-row justify-center items-center">
            <div>
              <a
                href={`${
                  import.meta.env.VITE_KEWNET_URL
                }/adminkew/goToOrder.php?id=${partOrder.order.id}`}
                target="_blank"
                data-tip="Kewnet"
                data-for="infos"
              >
                <BsInfoSquareFill className="h-6 w-6" />
              </a>
            </div>
            {renderPrintButton()}
          </div>
        </div>
        <button
          className="bg-white px-3 py-1 text-gray-700 my-3 absolute right-0 mr-12 mt-2"
          onClick={close}
        >
          <FiX className="h-6 w-6" />
        </button>
      </div>
      <div className="border-b border-gray-500 w-11/12 mt-2" />
    </div>
  );
};

export default HeaderOrderDetails;
