import { createEntityAdapter, createSlice } from '@reduxjs/toolkit';

import { AppRootState } from 'index';
import { kewloxApi } from 'services/kewloxApi';
import { Color, ColorJSON, createColor } from 'app/models/Color';

const colorAdapter = createEntityAdapter<Color>({
  selectId: color => color.id,
});

const colorsSlice = createSlice({
  name: 'colorsSlice',
  initialState: colorAdapter.getInitialState(),
  reducers: {},
  extraReducers: builder => {
    builder.addMatcher(
      kewloxApi.endpoints.getColors.matchFulfilled,
      (state, { payload }) => {
        const transformedPayload = payload.map((item: ColorJSON) =>
          createColor(item),
        );
        colorAdapter.setAll(state, transformedPayload);
      },
    );
  },
});

export const {} = colorsSlice.actions;

export const {
  selectById: selectColorById,
  selectIds: selectColorIds,
  selectEntities: selectColorEntities,
  selectAll: selectAllColors,
} = colorAdapter.getSelectors((state: AppRootState) => state.color);

// Add a selector for the color with the given reference
export const selectColorByReference = (reference: string) => (
  state: AppRootState,
) => selectAllColors(state).find(color => color.reference === reference);

export default colorsSlice.reducer;
