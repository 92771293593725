import React from 'react';
import TabsOrderDetails, {
  OrderDetailsTabs,
} from 'app/containers/OrderDetails/TabsOrderDetails';
import GeneralTab from 'app/containers/OrderDetails/GeneralTab';
import PiecesTab from 'app/containers/OrderDetails/PiecesTab';
import PackagesTab from 'app/containers/OrderDetails/PackagesTab';
import HeaderOrderDetails from 'app/containers/OrderDetails/HeaderOrderDetails';
import { PartOrder } from 'app/models/PartOrder';
import { Delivery } from 'app/models/Delivery';
import HistoryTab from 'app/containers/OrderDetails/HistoryTab';

interface Props {
  partOrder?: PartOrder;
  loading: boolean;
  displayOnly: boolean;
  close: any;
}

const OrderDetailsContent = ({
  partOrder,
  loading,
  displayOnly = false,
  close,
}: Props) => {
  const [tabSelected, setTabSelected] = React.useState(
    OrderDetailsTabs.General,
  );

  const handleTabChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setTabSelected(newValue);
  };

  if (!partOrder) return null;

  const getTab = () => {
    switch (tabSelected) {
      case OrderDetailsTabs.Pieces:
        return <PiecesTab loading={loading} />;
      case OrderDetailsTabs.Packages:
        if (partOrder.order.old)
          return (
            <div>
              Commande gérée par l'ancien programme d'expédition. Les colis
              peuvent être consultés ou modifiés dans l'ancien programme
              d'expédition.
            </div>
          );
        return <PackagesTab loading={loading} />;
      case OrderDetailsTabs.History:
        return <HistoryTab loading={loading} />;
      default:
        return <GeneralTab partOrder={partOrder} loading={loading} />;
    }
  };

  return (
    <div key={partOrder.id} className={`mx-8 bg-white h-full`}>
      <HeaderOrderDetails
        partOrder={partOrder}
        displayOnly={displayOnly}
        close={close}
        navigateInOrders={true}
      />
      <TabsOrderDetails
        tabSelected={tabSelected}
        handleTabChange={handleTabChange}
        hidePieces={partOrder.packageDelivery}
      />
      {getTab()}
    </div>
  );
};

export default OrderDetailsContent;
