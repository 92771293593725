import { PartOrder } from 'app/models/PartOrder';
import Modal from 'react-responsive-modal';
import React, { useEffect, useState } from 'react';
import TitleModal from 'app/components/Modal/TitleModal';
import { ProductOrder } from 'app/models/ProductOrder';
import { Checkbox } from '@mui/material';
import Img from 'app/components/Img';
import _ from 'lodash';
import {
  createProductionPiece,
  ProductionPiece,
  ProductionPieceJson,
} from 'app/models/ProductionPiece';
import Button from 'app/components/Button';
import { FiPrinter } from 'react-icons/fi';
import { GiCircularSawblade } from 'react-icons/gi';
import { FaWineGlass, RiArchiveDrawerLine } from 'react-icons/all';
import { Workstation } from 'app/containers/OrderDetails/types';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectDirectPartOrderProductsFiltered,
  selectHasCuttingPiece,
} from 'app/containers/OrderDetails/PartOrderSelection/selectors';
import PrintOrder from 'app/containers/OrderDetails/HeaderOrderDetails/PrintOrder';
import { PARTORDER_URL } from 'app/containers/SiteWrapper/constants';
import { requestPost } from 'utils/requests';
import {
  getAllPartOrders,
  getPartOrder,
} from 'app/containers/SiteWrapper/slice';
import { DESTINATION_LEUZE_ID } from 'app/constants';
import {
  selectOrderLoading,
  selectOrdersMap,
} from 'app/containers/SiteWrapper/selectors';
import { CircleSpinner } from 'react-spinners-kit';
import { CUTTING_PRINTING_INFO_URL } from 'app/containers/Cutting/constants';
import { Color, findColor } from 'app/models/Color';
import { CuttingToPrint } from 'app/containers/Cutting/CuttingToPrint';
import { selectAllColors } from 'store/slices/colorSlice';
import { useSubdividePartOrderMutation } from 'services/kewloxApi';

interface Props {
  partOrder: PartOrder;
  setIsPrintDirectPartOrderModal: any;
  isPrintDirectPartOrderModalOpen: boolean;
}

export function PrintDirectPartOrderModal({
  partOrder,
  isPrintDirectPartOrderModalOpen,
  setIsPrintDirectPartOrderModal,
}: Props) {
  const dispatch = useDispatch();
  const [selectedPieces, setSelectedPieces] = useState<number[]>([]);
  const [selectedProducts, setSelectedProducts] = useState<number[]>([]);
  const [filtered, setFiltered] = useState<boolean>(true);

  const [cuttingPieceToPrint, setCuttingPiecesToPrint] = useState<
    ProductionPiece[] | undefined
  >(undefined);

  const [currentStep, setPrintingStep] = useState<number | undefined>(
    undefined,
  );

  const [subdivise, { isLoading }] = useSubdividePartOrderMutation();

  const products = useSelector(selectDirectPartOrderProductsFiltered(filtered));
  const isOrderReLoading = useSelector(selectOrderLoading);
  const hasCutting = useSelector(selectHasCuttingPiece);
  const ordersMap = useSelector(selectOrdersMap);
  const colors = useSelector(selectAllColors);

  const handleSelectedProduct = (productId: number) => {
    const selected = new Set<number>(selectedProducts);
    if (selected.has(productId)) selected.delete(productId);
    else selected.add(productId);
    setSelectedProducts(Array.from(selected));
  };

  const handleSelectedPieces = (pieceId: number) => {
    const selected = new Set<number>(selectedPieces);
    if (selected.has(pieceId)) selected.delete(pieceId);
    else selected.add(pieceId);
    setSelectedPieces(Array.from(selected));
  };

  function productName(product: ProductOrder): string {
    if (product.name && product.name) return product.name;
    if (product.production.length === 1 && product.production[0].description) {
      return `${product.production[0].quantity} x ${product.production[0].description}`;
    }
    return '-';
  }

  useEffect(() => {
    if (!hasCutting) {
      if (currentStep === undefined) {
        setPrintingStep(1);
        setFiltered(false);
      }
    }
  }, [partOrder, currentStep]);

  useEffect(() => {
    if (products && (currentStep === 0 || currentStep === undefined)) {
      const selectedPiecesSet = new Set<number>([]);
      products.forEach(product => {
        product.production.forEach((piece: ProductionPiece) => {
          if (piece.station === Workstation.Drawer) {
            selectedPiecesSet.add(piece.id);
          }
        });
      });
      setSelectedPieces(Array.from(selectedPiecesSet));
    }
  }, [partOrder, filtered]);

  const handleCreateNewPartOrder = () => {
    const data = {};
    const dataPieces = selectedPieces.map(pieceId => ({
      id: pieceId,
    }));
    if (dataPieces && dataPieces.length > 0) {
      data['pieces'] = dataPieces;
    }

    if (selectedProducts.length > 0) {
      data['products'] = selectedProducts;
    }

    data['destination'] = DESTINATION_LEUZE_ID;

    subdivise({ id: partOrder.id, data })
      .unwrap()
      .then(() => {
        setPrintingStep(1);
        setSelectedPieces([]);
        setSelectedProducts([]);
      })
      .catch(error => {
        console.error('Error fetching part orders:', error);
      });

    // const url = `${PARTORDER_URL}${partOrder.id}/subdivide/`;
    // setLoading(true);
    // requestPost(url, data)
    //   .then(() => {
    //     dispatch(getPartOrder(partOrder.id));
    //     dispatch(getAllPartOrders());
    //     setLoading(false);
    //     setPrintingStep(1);
    //     setSelectedPieces([]);
    //     setSelectedProducts([]);
    //   })
    //   .catch(error => {
    //     alert('Une erreur est survenue');
    //     setLoading(false);
    //   });
  };

  const getCuttingPrintInfos = (partsOrdersId: number[]) => {
    requestPost(CUTTING_PRINTING_INFO_URL, {
      printPartsOrders: partsOrdersId,
    }).then((data: ProductionPieceJson[]) => {
      const piecesToPrint = data.map((pieceData: ProductionPieceJson) => {
        const piece = createProductionPiece(
          pieceData,
          undefined,
          ordersMap,
          colors,
        );
        if (
          (piece.station === Workstation.Woodwork ||
            piece.station === Workstation.WoodworkSpecial ||
            piece.station === Workstation.Drawer) &&
          piece.color === undefined
        )
          if (
            piece.reference.includes('ALU') ||
            piece.reference.includes('DIBOND')
          ) {
            piece.color = findColor('DIBOND', colors)!;
          } else {
            piece.color = findColor('WOOD', colors)!;
          }
        return piece;
      });
      setCuttingPiecesToPrint(piecesToPrint);
    });
  };

  const cuttingOnAfterPrint = () => {
    setCuttingPiecesToPrint(undefined);
    // dispatch(getAllPartOrders());
  };

  return (
    <Modal
      open={isPrintDirectPartOrderModalOpen}
      onClose={() => {
        setIsPrintDirectPartOrderModal(false);
        setPrintingStep(undefined);
        setFiltered(true);
      }}
      center
    >
      <ModalContentStyled className="flex items-center">
        <FiPrinter className="h-8 w-8 mr-4" />
        <TitleModal
          title={`Impression de la commande directe ${partOrder.identification}`}
        />
      </ModalContentStyled>
      {(currentStep === 0 || currentStep === undefined) && (
        <div>
          <div className="border-t py-1 my-1 flex">
            <div>
              Sélectionner les pièces qui seront produite{' '}
              <span className="font-semibold">ultérieurement</span> dans une
              sous-commande
            </div>
            {filtered && (
              <div
                className="underline ml-4 cursor-pointer"
                onClick={() => {
                  setFiltered(false);
                }}
              >
                Voir toute la liste
              </div>
            )}
          </div>
          <div className="overflow-y-auto">
            <div>
              {products
                ?.filter(p => !p.drawer)
                .map((product: ProductOrder) => (
                  <>
                    <div
                      key={`T-${product.id}`}
                      className="w-full flex items-center border cursor-pointer hover:bg-gray-100 mt-2"
                      onClick={() => {
                        handleSelectedProduct(product.id);
                      }}
                    >
                      <div className="w-1/12">
                        {product.production.length === 1 && (
                          <Checkbox
                            checked={selectedProducts.includes(product.id)}
                            color="primary"
                            name={`${product.id}`}
                          />
                        )}
                      </div>
                      <div className="w-11/12">
                        <div className="flex items-center justify-between text-xl ">
                          {productName(product)}
                          {product.image && (
                            <div className="mr-20 w-12 h-12">
                              <Img src={product.image} alt={`preview `} />
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                    {product.production.length > 1 &&
                      _.orderBy(
                        product.production.filter(piece => {
                          if (filtered) {
                            return (
                              piece.cutting ||
                              piece.station === Workstation.Drawer ||
                              piece.isFragile
                            );
                          } else return true;
                        }),
                        ['sorting', 'reference'],
                      ).map((piece: ProductionPiece) => (
                        <div
                          key={`P-${piece.id}`}
                          className={`flex border-b border-l border-r items-center h-8 justify-start text-base cursor-pointer hover:bg-gray-100 ${
                            selectedPieces.includes(piece.id)
                              ? 'bg-gray-200'
                              : ''
                          }`}
                          onClick={() => {
                            handleSelectedPieces(piece.id);
                          }}
                        >
                          <div className="w-1/6">
                            <div className="flex items-center">
                              <Checkbox
                                checked={selectedPieces.includes(piece.id)}
                                color="primary"
                                name={`${piece.id}`}
                              />
                              {piece.quantity} x
                            </div>
                          </div>
                          <div className="w-3/12 flex justify-start items-center">
                            {piece.cutting && (
                              <GiCircularSawblade className="h-5 w-5 mr-2 text-gray-700" />
                            )}
                            {piece.station === Workstation.Drawer && (
                              <RiArchiveDrawerLine className="h-5 w-5 mr-2 text-gray-700" />
                            )}
                            {piece.isFragile && (
                              <FaWineGlass className="h-5 w-5 mr-2 text-gray-700" />
                            )}
                            {piece.reference}
                          </div>
                          <div className="w-8/12 flex justify-start">
                            {piece.description}
                          </div>
                        </div>
                      ))}
                  </>
                ))}
            </div>
          </div>
          <div className="w-full flex justify-end items-center">
            <div className="mx-4">
              {selectedPieces.length + selectedProducts.length === 0 && (
                <div>
                  Le client va retirer la totalité de sa commande
                  <span className="font-semibold ml-1">aujourd'hui</span>
                </div>
              )}
              {selectedPieces.length + selectedProducts.length === 1 && (
                <div>
                  <span className="font-semibold">1 pièce</span> ne sera pas
                  produite aujourd'hui et devra être enlevée{' '}
                  <span className="font-semibold ml-1">dans le future</span>
                </div>
              )}
              {selectedPieces.length + selectedProducts.length > 1 && (
                <div>
                  <span className="font-semibold">
                    {selectedPieces.length + selectedProducts.length} pièces
                  </span>{' '}
                  ne seront pas produite aujourd'hui et devront être enlevées{' '}
                  <span className="font-semibold ml-1">dans le future</span>
                </div>
              )}
            </div>
            <div className="my-2">
              <Button
                onClick={() => {
                  if (
                    selectedPieces.length > 0 ||
                    selectedProducts.length > 0
                  ) {
                    handleCreateNewPartOrder();
                  } else {
                    setPrintingStep(1);
                    setSelectedPieces([]);
                    setSelectedProducts([]);
                  }
                }}
                contentText="Suivant"
                loading={isLoading}
              />
            </div>
          </div>
        </div>
      )}
      {currentStep === 1 && (
        <div>
          <div className="flex items-center justify-center">
            {isOrderReLoading ? (
              <div className="my-12">
                <CircleSpinner size={35} color="#4a5568" />
              </div>
            ) : (
              <div>
                <div className="flex flex-col my-12">
                  <div className="flex items-center my-2 text-xl">
                    Imprimer les feuilles de décomposition:{' '}
                    <PrintOrder order={partOrder} />
                  </div>

                  {hasCutting && (
                    <div>
                      <div className="border-t border-gray-200 my-5" />
                      <div className="flex items-center my-2 text-xl">
                        Imprimer les feuilles de découpes:{' '}
                        <button
                          className="mt-2 ml-2"
                          data-tip="Imprimer"
                          data-for="infos"
                          onClick={() => {
                            getCuttingPrintInfos([partOrder.id]);
                          }}
                        >
                          <FiPrinter className="h-6 w-6 ml-2" />
                        </button>
                      </div>
                    </div>
                  )}
                </div>
                <button
                  className="border py-1 px-2 border-gray-700"
                  onClick={() => {
                    setPrintingStep(0);
                  }}
                >
                  Créer une sous-commande
                </button>
              </div>
            )}
          </div>
        </div>
      )}
      {cuttingPieceToPrint && (
        <CuttingToPrint
          pieces={cuttingPieceToPrint}
          onAfterPrint={cuttingOnAfterPrint}
        />
      )}
    </Modal>
  );
}

export const ModalContentStyled = styled.div`
  width: 850px;
`;
